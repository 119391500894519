:deep(.ant-modal-body) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.form-item {
  display: flex;
  font-size: 0.18rem;
  color: #555555;
  line-height: 1.667;
  margin-bottom: 0.1rem;
}

.form-item .label {
  width: 1.7rem;
  flex-shrink: 0;
}

.form-item .input {
  flex: 1;
  position: relative;
}

.form-item .limit {
  position: absolute;
  right: 0.1rem;
  bottom: 0.1rem;
  color: #999;
}

.form-item .content {
  max-height: 2rem;
  overflow-y: auto;
}

:deep(canvas) {
  position: relative;
}

:deep(.c__panel) {
  left: 0;
  top: auto;
  bottom: 0.4rem;
}

:deep(.c__color-picker) {
  cursor: pointer;
}

.ant-input {
  width: 100%;
  margin-top: 0.05rem;
  font-size: 0.18rem;
  color: #555555;
  border: 0.01rem solid #e5e5e5;
  border-radius: 0.1rem;
  padding: 0.1rem;
  padding-bottom: 0.3rem;
}

:deep(.ant-form-explain) {
  padding-left: 1.7rem;
}

.color-picker {
  display: flex;
  align-items: flex-start;
}

.color-picker .color {
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
}

.color-picker .c__color-picker {
  margin-left: 0.2rem;
}

.color-picker .c__color-picker .c__block {
  width: 0.32rem;
  height: 0.32rem;
}

.color-picker :deep(.c__block) {
  width: 0.32rem;
  height: 0.32rem;
}