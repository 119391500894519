.pop_inner {
  width: 400PX;
}

.pop_inner .title {
  font-size: 0.2rem;
  line-height: 1.167;
  color: #001529;
  padding: 0.06rem 0;
  margin-bottom: 0.1rem;
}

.pop_inner .source {
  font-size: 0.16rem;
  line-height: 1.5;
  margin-bottom: 0.1rem;
}

.pop_inner .content {
  font-size: 0.16rem;
  line-height: 1.5;
  margin-bottom: 0.1rem;
}

.pop_inner .tags {
  margin-bottom: 0.1rem;
}

.pop_inner .tags :deep(.a-tag) {
  margin-bottom: 0.05rem;
}

:deep(.ant-modal-content) {
  border-radius: 0.04rem;
  position: relative;
  overflow: visible;
}

:deep(.ant-modal-content)::after {
  content: '';
  position: absolute;
  display: block;
  width: 0.084853rem;
  height: 0.084853rem;
  background: transparent;
  border-style: solid;
  border-width: 0.042426rem;
  transform: rotate(-135deg);
  border-color: transparent #fff #fff transparent;
  box-shadow: 0.03rem 0.03rem 0.07rem rgba(0, 0, 0, 0.07);
  top: -0.032rem;
  left: 0.2rem;
}

:deep(.ant-modal) {
  transform-origin: 0 0 !important;
  margin: 0;
}