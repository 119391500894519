.c__color-picker {
  position: relative;
  padding: 0.03rem;
  border: 0.01rem solid #e6e6e6;
  border-radius: 0.04rem;
  display: inline-block;
  margin-right: 0.3rem;
}

.c__block {
  width: 0.18rem;
  height: 0.18rem;
  border-radius: 0.02rem;
}

.c__panel {
  position: absolute;
  top: 0.3rem;
  left: -1.37rem;
  z-index: 2001;
  transform-origin: center top;
  padding: 0.06rem;
  box-sizing: content-box;
  background-color: #FFF;
  border: 0.01rem solid #EBEEF5;
  border-radius: 0.04rem;
  box-shadow: 0 0.02rem 0.12rem 0 rgb(0 0 0 / 10%);
}

.c__canvas {
  position: relative;
}

.c__canvas canvas {
  left: 0;
  top: 0;
}

.c__cur {
  width: 0.03rem;
  height: 0.03rem;
  outline: 0.02rem solid #fff;
  margin-left: -0.01rem;
  margin-top: -0.01rem;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 0 0.015rem #fff, inset 0 0 0.01rem 0.01rem rgb(0 0 0 / 30%), 0 0 0.01rem 0.02rem rgb(0 0 0 / 40%);
}

.c__bar,
.c__alpha {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  border-radius: 0.01rem;
  border: 0.01rem solid #f0f0f0;
  box-shadow: 0 0 0.02rem rgb(0 0 0 / 60%);
}

.c__bar {
  width: 0.2rem;
  height: 0.03rem;
  left: 0;
  top: 0;
  margin-left: -0.01rem;
  margin-top: -0.02rem;
}

.c__alpha {
  width: 0.05rem;
  height: 0.2rem;
  left: 100%;
  top: 0;
  margin-left: -0.03rem;
  margin-top: -0.01rem;
}

.c__alpha-silder {
  position: relative;
  box-sizing: border-box;
  width: 2.56rem;
  margin-left: 0.3rem;
  height: 0.18rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}

.c__alpha-silder-bar {
  position: relative;
  background: linear-gradient(to right, rgba(255, 69, 0, 0) 0%, rgb(255, 69, 0) 100%);
  height: 100%;
}

.c__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.05rem;
}

.c__input {
  background-color: #FFF;
  background-image: none;
  border-radius: 0.04rem;
  border: 0.01rem solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  outline: 0;
  padding: 0 0.15rem;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 1.6rem;
  font-size: 0.12rem;
  height: 0.28rem;
  line-height: 0.28rem;
}

.c__input:focus {
  border-color: #409EFF;
}

.c__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c__btn-clear {
  font-size: 0.12rem;
  color: #409EFF;
  cursor: pointer;
}

.c__btn-confirm {
  padding: 0.07rem 0.15rem;
  margin-left: 0.1rem;
  font-size: 0.12rem;
  border-radius: 0.03rem;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #FFF;
  border: 0.01rem solid #DCDFE6;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  font-weight: 500;
}