:deep(.mce-edit-focus) {
  outline: none;
}

.editor {
  padding: 0.15rem 0.2rem 0.2rem;
  font-size: 0.16rem;
  white-space: normal;
  outline: none;
  position: relative;
  cursor: text;
  line-height: 22pt;
}

.editor * {
  font-family: 'Miscrosoft YaHei' !important;
}

:deep(.mce-resizehandle) {
  display: none;
  border: 0 !important;
}

:deep(.mce-content-body) {
  overflow: auto;
}

:deep(.mce-content-body)::-webkit-scrollbar {
  border-radius: 0.03rem;
  height: 0.06rem;
  background-color: #e5e5e5;
}

:deep(.mce-content-body)::-webkit-scrollbar-thumb {
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

:deep(.mce-content-body) audio[data-mce-selected],
:deep(.mce-content-body) embed[data-mce-selected],
:deep(.mce-content-body) img[data-mce-selected],
:deep(.mce-content-body) object[data-mce-selected],
:deep(.mce-content-body) table[data-mce-selected],
:deep(.mce-content-body) video[data-mce-selected] {
  outline: none;
}

.popover_txt {
  position: fixed;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  padding: 0.05rem;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 0.04rem;
  box-shadow: 0 0.02rem 0.08rem rgba(0, 0, 0, 0.25);
}

.popover_txt :deep(.ant-btn) {
  padding: 0 0.1rem;
  font-size: 0.14rem;
  border: 0;
  border-right: 0.01rem solid #00ffc0;
  background-color: #00ffc0;
  color: #333;
}

.popover_txt :deep(.ant-btn):last-of-type {
  border-right: 0;
  margin-left: 0.1rem;
}

.popover_txt :deep(.ant-btn):focus {
  color: rgba(0, 0, 0, 0.65);
}

.popover_txt :deep(.ant-btn):hover {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.65);
}

.popover_txt :deep(.ant-popover-arrow) {
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) rotate(45deg);
  border-color: transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65) transparent;
  box-shadow: 0.03rem 0.03rem 0.07rem rgba(0, 0, 0, 0.07);
}

@media (max-width: 1024px) {
  .editor {
    font-size: 0.24rem;
    line-height: 1.5;
  }

  .popover_txt {
    display: none !important;
  }
}